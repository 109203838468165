export const environment = {
    production: true,
    logErrorInConsole: true,
    environmentName: "test",
    grafanaMetricsUrl: "https://metrics.impacgroup.de/d/dfSKW-1Ik/dorr-testsystem-camera-statistics?orgId=1&var-imei=",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    oauthBaseAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/auth/",
    usersAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/users/",
    rolesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/roles/",
    organizationsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/organizations/",
    profileAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/profile/",
    resetPasswordAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/resetpassword/",
    languagesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/languages/",
    smtpMailAccountsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/mailaccounts/",
    mailtemplatesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/mailtemplates/",
    registrationConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/registrationconfig/",
    firebaseConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/firebaseconfig/",
    firebaseDevicesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/firebasedevices/",
    firebaseNotificationsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/firebasenotifications/",
    firebaseNotificationTemplatesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/firebasenotificationtemplates/",
    paypalConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/paypalconfig/",
    paypalBillingPlansAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/paypalbillingplans/",
    paypalBillingAgreementsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/paypalbillingagreements/",
    klarnaConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/klarnaconfig/",
    klarnaAboPlansAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/klarnaaboplans/",
    klarnaAboAgreementsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/klarnaaboagreements/",
    webSmsConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/websmsconfig/",
    webSmsNotificationsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/websmsnotifications/",
    systemLogMessagesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/systemlogs/",
    systemJobPerformancesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/systemjobs/",
    systemResourceSnapshotsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/systemresource/",
    systemErrorsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/systemerrors/",
    imeisAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/imeis/",
    camerasAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v2/admin/cameras/",
    cameras4GProAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v2/admin/cameras/cloud_4g_pro/",
    cameras4GProWaAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v2/admin/cameras/cloud_4g_pro_wa/",
    cameras4GProRCAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v2/admin/cameras/cloud_4g_pro_rc/",
    cameras4GProRCWaAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v2/admin/cameras/cloud_4g_pro_rc_wa/",
    cameras4GProMiniAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v2/admin/cameras/cloud_4g_pro_mini/",
    aboUsersAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/abos/",
    firmwaresAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/firmwares/",
    appleConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/appleconfig/",
    googleConfigAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/googleconfig/",
    crashlogApplicationsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/crashlogapplications/",
    crashlogErrorsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/crashlogerrors/",
    crashlogMessagesAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/crashlogmessages/",
    textPoolsAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/textpools/",
    stolenCamerasAPI: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/stolencams/"
};
